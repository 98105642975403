
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
	<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F90f976ef-afdc-4704-ab3e-7d407f4af082?alt=media&token=c640caee-9628-48e4-9eaf-6f1d832a40bb"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2Fdefa3ee9-8d9b-4db0-89f5-1fb2110faa7a?alt=media&token=dcb1be99-7e79-408e-8cf4-185991d98031"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F33c6f913-ffe2-419a-9996-89c92015385a?alt=media&token=9a906a86-bc6f-44dd-91c8-46820323f7cf"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F88a7cdaa-26b2-4877-88ed-25dbb09049e1?alt=media&token=09d3d7da-2f8f-4fc6-8cd3-ae76799d85f5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F0e3c467a-36d7-415f-8755-2458ec51c100?alt=media&token=64a72f24-badd-40d8-ab7c-0487c0dcc05d"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F951262a8-7a0c-4efa-9eb8-3348b896f1cd?alt=media&token=d3650599-c134-4f57-a6df-050aca1fecf8"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F92e4a234-c5b2-4b6a-afa5-a8e85e5ea3b8?alt=media&token=76ed90a4-47b0-49b6-996d-2d25dbf65001"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F5437e4e9-dcd4-4157-886b-2a7e6cc950ba?alt=media&token=bf332c7f-17d9-4eee-b7f8-0e9ec5488fce"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F9967e3a3-1a21-4e8a-94b5-118010782496?alt=media&token=432b26fe-4763-49a0-bc21-0f7b6b036518"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F86957537-0691-4168-8b13-93cfd043c6ab?alt=media&token=75881a63-6e80-43a2-a200-117ae9646326"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2Fffe44362-a8b8-403e-acfe-817cb3357ae1?alt=media&token=ebbc3b7f-426a-4747-bda6-c7647dee0a84"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F937abc58-1ced-4bb6-af6d-c52ef7520421?alt=media&token=43aeaae8-431b-4db7-8ba3-f24ddee43489"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2Fdf6bc836-6eb2-4996-816b-9b1e27e00fe1?alt=media&token=6f2a2f6b-b58e-4564-8b2a-4d7f2aa154bf"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2Fd36e5223-2580-4bf3-a404-b7d334dd776d?alt=media&token=9ca8da0c-e720-4954-bf91-f233f765ae9a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F269b91ad-4f61-4b84-9ad8-c037385d5be6?alt=media&token=b8e955a5-54db-43e5-88c3-b175870b8bb4"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F74da73ae-0057-4bb8-aada-d9c1be30ca8a?alt=media&token=3919209e-3491-44ca-ae35-73737b0668c1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F2f1bb2a1-9a0d-4a93-886d-a4f2b568425a?alt=media&token=2c7a4c71-38db-4ab8-8dc1-6dc6b57ee219"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2Ff1be888d-f580-42e4-a28f-8fac3fa7d8ad?alt=media&token=50a97cfd-7cd7-48e7-87f6-ae269099d58a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F9f247d07-8ef6-4940-8765-fbf071d8abcd?alt=media&token=c68bb944-36b6-4670-aad6-debfd665942b"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F37ba30fa-cd04-470b-81d0-91f342721bc1?alt=media&token=245eafb7-ea46-45f3-8ade-31b71dd7caf5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F7c789a14-a94d-441f-ab01-fe0e8f2b5736?alt=media&token=4cdca8ef-a8e6-43c2-beb5-6bf328a428ba"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2Fc260c2ab-428c-49be-b2db-41ac404a84ee?alt=media&token=b235458f-3a31-4194-9f7d-4b19bf805c5f"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2F832b7ce9-610c-4d0d-b6ea-e79e4e5b28f3?alt=media&token=f2b4871d-1d3c-491c-ac54-732f1f3901c1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2F%20English%2F%20DIVU8EN%2FDIVU8ENMAT.pdf?alt=media&token=d7931982-2975-4af9-af1e-3bbf056cf171' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
